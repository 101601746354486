import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field label large" }
const _hoisted_2 = { class: "field label large" }
const _hoisted_3 = { class: "field label large" }
const _hoisted_4 = { class: "field label large" }
const _hoisted_5 = { class: "field label large textarea" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tickets_input = _resolveComponent("tickets-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_tickets_input, {
        label: "Vorname *",
        cy: "firstname",
        modelValue: $setup.reg.firstname,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.reg.firstname) = $event)),
        val: $setup.v$.firstname
      }, null, 8, ["modelValue", "val"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tickets_input, {
        label: "Nachname *",
        cy: "lastname",
        modelValue: $setup.reg.lastname,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.reg.lastname) = $event)),
        val: $setup.v$.lastname
      }, null, 8, ["modelValue", "val"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_tickets_input, {
        label: "Mobile *",
        cy: "phone",
        modelValue: $setup.reg.phone,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.reg.phone) = $event)),
        val: $setup.v$.phone,
        onBlurred: $setup.format
      }, null, 8, ["modelValue", "val"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_tickets_input, {
        label: "E-Mail",
        cy: "email",
        modelValue: $setup.reg.email,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.reg.email) = $event)),
        val: $setup.v$.email
      }, null, 8, ["modelValue", "val"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_tickets_input, {
        label: "Bemerkungen",
        type: "textarea",
        cy: "notes",
        modelValue: $setup.reg.notes,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.reg.notes) = $event)),
        val: $setup.v$.notes
      }, null, 8, ["modelValue", "val"])
    ]),
    _createElementVNode("nav", null, [
      _createElementVNode("button", {
        class: "round primary",
        disabled: $setup.v$.$errors.length > 0,
        "data-cy": "save",
        onClick: $setup.save
      }, "Speichern", 8, _hoisted_6),
      _createElementVNode("button", {
        class: "round secondary",
        onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.emit('cancel')))
      }, "Abbrechen")
    ])
  ], 64))
}