import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["data-cy", "type", "value"]
const _hoisted_2 = ["data-cy", "value"]
const _hoisted_3 = {
  key: 2,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.type !== 'textarea')
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          "data-cy": $props.cy,
          class: _normalizeClass($props.inputclass),
          type: $props.type,
          value: $props.modelValue,
          onInput: $setup.updateValue,
          onBlur: $setup.blur
        }, null, 42, _hoisted_1))
      : _createCommentVNode("", true),
    ($props.type === 'textarea')
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 1,
          "data-cy": $props.cy,
          rows: "4",
          class: _normalizeClass($props.inputclass),
          value: $props.modelValue,
          onInput: $setup.updateValue,
          onBlur: $setup.blur
        }, null, 42, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("label", null, _toDisplayString($props.label), 1),
    ($props.val?.$error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.val?.$errors[0].$message), 1))
      : _createCommentVNode("", true)
  ], 64))
}