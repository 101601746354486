import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "right-align" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", $setup.data ? 'active' : ''])
  }, [
    _createElementVNode("h5", null, _toDisplayString($setup.data?.title), 1),
    _createElementVNode("div", null, _toDisplayString($setup.data?.text), 1),
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("button", {
        class: "border",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.cancel())),
        "data-cy": "modal-button-cancel"
      }, _toDisplayString($setup.data?.cancelButtonText), 1),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.ok())),
        "data-cy": "modal-button-ok"
      }, _toDisplayString($setup.data?.okButtonText), 1)
    ])
  ], 2))
}