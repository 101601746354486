import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import TicketsGame from '@/components/TicketsGame.vue'
import TicketsGameShow from '@/components/TicketsGameShow.vue'
import TicketsGameEdit from '@/components/TicketsGameEdit.vue'
import TicketsGameInputs from '@/components/TicketsGameInputs.vue'
import TicketsGameInputsModal from '@/components/TicketsGameInputsModal.vue'
import TicketsReservation from '@/components/TicketsReservation.vue'
import TicketsReservationEdit from '@/components/TicketsReservationEdit.vue'
import TicketsReservations from '@/components/TicketsReservations.vue'
import TicketsRegistrations from '@/components/TicketsRegistrations.vue'
import TicketsRegistration from '@/components/TicketsRegistration.vue'
import TicketsRegistrationAdd from '@/components/TicketsRegistrationAdd.vue'
import TicketsCalAbo from '@/components/TicketsCalAbo.vue'

import { AnLoader } from 'affolternet-vue3-library'
import { AnToasts } from 'affolternet-vue3-library'
import { AnToast } from 'affolternet-vue3-library'

import TicketsModal from '@/components/TicketsModal.vue'
import TicketsInput from '@/components/TicketsInput.vue'


import 'beercss'
import 'material-dynamic-colors'
import 'affolternet-vue3-library/css'
import { loaderService } from 'affolternet-vue3-library'
import { toastService } from 'affolternet-vue3-library'

const app = createApp(App)

app.component('TicketsGame', TicketsGame)
app.component('TicketsGameShow', TicketsGameShow)
app.component('TicketsGameEdit', TicketsGameEdit)
app.component('TicketsGameInputs', TicketsGameInputs)
app.component('TicketsGameInputsModal', TicketsGameInputsModal)
app.component('TicketsReservations', TicketsReservations)
app.component('TicketsReservation', TicketsReservation)
app.component('TicketsReservationEdit', TicketsReservationEdit)
app.component('TicketsRegistrations', TicketsRegistrations)
app.component('TicketsRegistration', TicketsRegistration)
app.component('TicketsRegistrationAdd', TicketsRegistrationAdd)
app.component('TicketsCalAbo', TicketsCalAbo)

app.component('AnLoader', AnLoader)
app.component('AnToasts', AnToasts)
app.component('AnToast', AnToast)
app.component('TicketsModal', TicketsModal)
app.component('TicketsInput', TicketsInput)

app.provide('loaderService', loaderService)
app.provide('toastService', toastService)

app.use(router).mount('#app')
