import { ApiResponse } from '@/models/ApiResponse';
import { loaderService } from 'affolternet-vue3-library';
import { toastService } from 'affolternet-vue3-library';

let instance: Api | null = null

class Api {

  constructor() {
    if (!instance) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      instance = this
    }
    return this
  }

  public async get<T>(url: string): Promise<ApiResponse<T>> {
    loaderService.showLoader()
    const response = await fetch(url, {
      method: "get",
      headers: {
        'Accept': 'application/json'
      }
    })
    try {
      if (!response.ok) {
        toastService.showError(`get failed, response.status = ${response.status}`)
        ApiResponse.getError(response, `request failed: ${response.status}`)
      }
      const fi = await response.json()
      loaderService.hideLoader()
      return new ApiResponse<T>(fi, response)
    } catch (error) {
      return this.displayException(error, response)
    } finally {
      loaderService.hideLoader()
    }
  }

  public async post<T, R>(url: string, data: T): Promise<ApiResponse<R>> {
    loaderService.showLoader()
    const body = JSON.stringify(data)
    const response = await fetch(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body
    })

    try {
      if (response.ok) {
        const json = await response.json()
        return new ApiResponse<R>(json, response)
      } else {
        if (response.status === 412) {
          // etag error
          toastService.showWarning('Jemand anderer hat vor ihnen gespeichert. Diese Änderungen wurden geladen.')
          const json = await response.json()
          return new ApiResponse<R>(json, response)
        } else {
          return ApiResponse.getError(response, `request failed: ${response.status}`)
        }
      }
    } catch (error) {
      return this.displayException(error, response)
    } finally {
      loaderService.hideLoader()
    }
  }

  public async delete<R>(url: string): Promise<ApiResponse<R>> {
    loaderService.showLoader()
    const response = await fetch(url, {
      method: 'delete',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })

    try {
      if (response.ok) {
        const json = await response.json()
        return new ApiResponse<R>(json, response)
      } else {
        if (response.status === 412) {
          // etag error
          toastService.showWarning('Jemand anderer hat vor ihnen gespeichert. Diese Änderungen wurden geladen.')
          const json = await response.json()
          return new ApiResponse<R>(json, response)
        } else {
          return ApiResponse.getError(response, `request failed: ${response.status}`)
        }
      }
    } catch (error) {
      return this.displayException(error, response)
    } finally {
      loaderService.hideLoader()
    }
  }

  private displayException<R>(error: unknown, response: Response): ApiResponse<R> {
    let err: string
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON 
      err = 'SyntaxError: Response konnte nicht gelesen werden'
    } else {
      err = `response failed: ${response.status}`
    }
    return ApiResponse.getError(response, err)
  }

}

export const api = new Api()