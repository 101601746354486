const prefix = 'antix_'
const icsFileKey = `${prefix}selected_file`

export const getFile = (encode = false): string | null => {
  const item = localStorage.getItem(icsFileKey)
  if (item) {
    return encode ? encodeURI(item) : item
  }
  return null
};

export const saveFile = (filename: string): void => {
  localStorage.setItem(icsFileKey, filename)
};

export const deleteFile = (): void => {
  delete localStorage[icsFileKey]
}

class Settings {
  public get saveInputs(): boolean {
    const saved = localStorage.getItem(`${prefix}saveInputs`)
    if (saved === null) {
      this.saveInputs = true
      return true
    }
    return saved === 'true'
  }
  public set saveInputs(val: boolean) {
    localStorage.setItem(`${prefix}saveInputs`, val ? 'true' : 'false')
  }
  public get firstName(): string {
    return this.saveInputs ? localStorage.getItem(`${prefix}firstName`) ?? '' : ''
  }
  public set firstName(val: string) {
    if (this.saveInputs) {
      localStorage.setItem(`${prefix}firstName`, val)
    }
  }
  public get lastName(): string {
    return this.saveInputs ? localStorage.getItem(`${prefix}lastName`) ?? '' : ''
  }
  public set lastName(val: string) {
    if (this.saveInputs) {
      localStorage.setItem(`${prefix}lastName`, val)
    }
  }
  public get phoneNumber(): string {
    return this.saveInputs ? localStorage.getItem(`${prefix}phoneNumber`) ?? '' : ''
  }
  public set phoneNumber(val: string) {
    if (this.saveInputs) {
      localStorage.setItem(`${prefix}phoneNumber`, val)
    }
  }
  public get email(): string {
    return this.saveInputs ? localStorage.getItem(`${prefix}email`) ?? '' : ''
  }
  public set email(val: string) {
    if (this.saveInputs) {
      localStorage.setItem(`${prefix}email`, val)
    }
  }
  public get showAllGames(): string {
    return localStorage.getItem(`${prefix}showAllGames`) ?? 'false'
  }
  public set showAllGames(val: string) {
    localStorage.setItem(`${prefix}showAllGames`, val)
  }
}

export const settings = new Settings()
