import { api } from './Api';
import { Stadium } from '../types/Stadium';
import { ServiceResponse } from './../models/ServiceResponse';
let instance: HomeService | null = null;

class HomeService {

  public stadiums: Stadium[] = []

  constructor() {
    if (!instance) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      instance = this
    }
    return this
  }

  public async loadHome(): Promise<ServiceResponse<Stadium[]>> {
    const response = await api.get<Stadium[]>('api/stadiums')
    if (response.success) {
      this.stadiums = response.data
    }
    return new ServiceResponse(response, response.data)
  }

  public async reload(): Promise<ServiceResponse<Stadium[]>> {
    const response = await api.get<Stadium[]>('api/reload')
    if (response.success) {
      this.stadiums = response.data
    }
    return new ServiceResponse(response, response.data)
  }
}

export const homeService = new HomeService()
