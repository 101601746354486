export class ApiResponse<T> {

  public data: T
  public success: boolean
  public response!: Response
  public error: string | null = null

  public static getError<T>(response: Response, error: string): ApiResponse<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const err = new ApiResponse<T>(null as any, response, true)
    err.error = error
    return err
  }

  constructor(data: T, response: Response, error = false) {
    this.data = data
    this.success = !error
    this.response = response
  }

}