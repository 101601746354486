import { Registration } from '@/types/Registration'
import { Seat } from '@/types/Seat'
import _ from 'lodash'
import { Game } from './Game'
import { userService } from '@/services/UserService'

export class ViewProperties {
  public filename: string
  public game: Game
  public seats: Seat[] = []
  public registrations: Registration[] = []
  public isAdmin = false
  public isLoggedIn = false
  public isVisible = true

  constructor(filename: string, game: Game, seats: Seat[], registrations: Registration[], showAllGames: boolean) {
    this.filename = filename
    this.game = game
    this.seats = seats.filter(s => filename.indexOf(s.stadium) === 0)
    this.registrations = _.sortBy(registrations, (r: Registration) => r.lastname, (r: Registration) => r.firstname)
    this.isAdmin = userService.user?.isAdmin || false
    this.isLoggedIn = userService.isLoggedIn()
    if (!showAllGames) {
      const yesterday= new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      this.isVisible = new Date(game.dtstart) >= yesterday
    }
  }

  public get fullyBooked(): boolean {
    return this.game.reservations.length === this.seats.length
  }

  public get availableSeats(): Seat[] {
    return this.multiPropSort(this.seats, ['block', 'row', 'seat'])
      .filter(s => this.isAdmin || userService.isSeatOwner(s.id))
      .filter(s => {
        return this.game.reservations.map(g => g.seatid).indexOf(s.id) === -1
      })
  }

  public get unconfirmedRegistrations(): Registration[] {
    return this.registrations.filter(r => {
      return this.game.reservations.map(res => res.registrationid).indexOf(r.registrationId) === -1
    })
  }

  public get isEditor(): boolean {
    return userService.user?.isAdmin || userService.isGameEditor(this.filename)
  }

  public get isGameEditor(): boolean {
    if (!userService.user?.storedUser) {
      return false
    }
    return userService.user?.isAdmin || userService.user?.storedUser.isGameEditor
  }

  private multiPropSort(array: Seat[], props: Array<keyof Seat>): Seat[] {
    return array.sort((a, b) => {
        for (const prop of props) {
          const comparison = (a[prop] as string).localeCompare(b[prop] as string);
          if (comparison !== 0) return comparison;
        }
        return 0;
    });
}
}