import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import TicketsHome from '@/views/TicketsHome.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'TicketsHome',
    component: TicketsHome
  },
  {
    path: '/games',
    name: 'TicketsGames',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "list" */ '../views/TicketsGames.vue')
  },
  {
    path: '/list',
    name: 'TicketsList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "list" */ '../views/TicketsList.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "about" */ '../views/TicketsUser.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
