import { ServiceResponse } from './../models/ServiceResponse';
import { TicketsUser } from "@/types/TicketsUser"
import { api } from '@/services/Api'

let instance: UserService | null = null;

class UserService {

  public user: TicketsUser | null = null

  constructor() {
    if (!instance) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      instance = this
    }
    return this
  }

  public async loadUser(): Promise<ServiceResponse<TicketsUser | null>> {
    const response = await api.get<TicketsUser>('api/user')
    if (response.success) {
      this.user = response.data
    }
    return new ServiceResponse(response, response.data)
  }

  public isLoggedIn(): boolean {
    return !!this.user?.userName
  }

  public getBaseUrl(): string {
    const loc = window.location
    let path = `${loc.protocol}//${loc.hostname}`
    if (loc.port && loc.port !== '80') {
      path += `:${loc.port}`
    }
    return path
  }

  public isGameEditor(filename: string): boolean {
    if (!this.user || !this.user.storedUser) {
      return false
    }
    const hasSeatsForFile = this.user.seats.filter(s => filename.indexOf(s.stadium) === 0)
    return hasSeatsForFile.length > 0
  }

  public isSeatOwner(seatid: string): boolean {
    if (!this.user || !this.user.storedUser) {
      return false
    }
    const ownedSeats = this.user.seats.filter(s => s.id == seatid)
    return ownedSeats.length > 0
  }

  public login(): void  {
    window.location.href = `${this.getBaseUrl()}/login`
  }

  public logout(): void {
    window.location.href = `${this.getBaseUrl()}/logout`
  }
}

export const userService = new UserService()
