import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid medium-space" }
const _hoisted_2 = { class: "s12 m9 l6" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "s12 m9 l6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tickets_reservations = _resolveComponent("tickets-reservations")!
  const _component_tickets_registrations = _resolveComponent("tickets-registrations")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", null, _toDisplayString($props.viewProperties.game.summary), 1),
      _createElementVNode("p", null, _toDisplayString($props.viewProperties.game.getDateTime()), 1),
      _createElementVNode("p", null, _toDisplayString($props.viewProperties.game.location), 1),
      ($props.viewProperties.isEditor)
        ? (_openBlock(), _createElementBlock("nav", _hoisted_3, [
            ($props.viewProperties.isGameEditor)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "round primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('edit')))
                }, "Bearbeiten"))
              : _createCommentVNode("", true),
            ($props.viewProperties.isGameEditor)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "round secondary",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('delete')))
                }, "Löschen"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_tickets_reservations, {
        "view-properties": $props.viewProperties,
        onDeleteRes: $setup.deleteRes
      }, null, 8, ["view-properties"]),
      _createVNode(_component_tickets_registrations, {
        "view-properties": $props.viewProperties,
        onUpdateReg: $setup.updateReg,
        onAddReservation: $setup.addReservation
      }, null, 8, ["view-properties"]),
      _createElementVNode("p", null, "Total Sitze: " + _toDisplayString($props.viewProperties.seats.length), 1)
    ])
  ]))
}