import { Registration } from './../types/Registration'
import { ServiceResponse } from '@/models/ServiceResponse'
import { api } from './Api'

let instance: RegistrationService | null = null;

class RegistrationService {

  constructor() {
    if (!instance) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      instance = this
    }
    return this
  }

  public async getRegistrations(): Promise<ServiceResponse<Registration[]>> {
    const regUrl = '/api/registrations/'
    const response = await api.get<Registration[]>(regUrl);
    return new ServiceResponse(response, response.data)
  }

  public async saveRegistration(reg: Registration): Promise<ServiceResponse<Registration[] | []>>  {
    const regUrl = `/api/registrations/${reg.gameId}`
    const response = await api.post<Registration, Registration[]>(regUrl, reg)
    if (response.success) {
      const sr = new ServiceResponse<Registration[]>(response, response.data)
      return sr
    }
    return new ServiceResponse<[]>(response, [])
  }

  public async deleteRegistration(reg: Registration): Promise<ServiceResponse<Registration[] | []>>  {
    const regUrl = `/api/registrations/${reg.gameId}/${reg.registrationId}`
    const response = await api.delete<Registration[]>(regUrl)
    if (response.success || response.response.status === 412) {
      const sr = new ServiceResponse<Registration[]>(response, response.data)
      return sr
    }
    return new ServiceResponse<[]>(response, [])
  }
}

export const registrationService = new RegistrationService()
