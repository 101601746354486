import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid medium-space" }
const _hoisted_2 = { class: "s12 m9 l6" }
const _hoisted_3 = { class: "s12 m9 l6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tickets_game_inputs = _resolveComponent("tickets-game-inputs")!
  const _component_tickets_reservations = _resolveComponent("tickets-reservations")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tickets_game_inputs, {
        game: $props.viewProperties.game,
        onSave: $setup.save,
        onCancel: $setup.cancel
      }, null, 8, ["game"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_tickets_reservations, { "view-properties": $props.viewProperties }, null, 8, ["view-properties"])
    ])
  ]))
}