export class ModalData {
  public title: string
  public text: string
  public okButtonText: string
  public cancelButtonText: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public data: any | null = null

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(title: string, text: string, okButtonText: string, cancelButtonText: string, data: any) {
    this.title = title
    this.text = text
    this.okButtonText = okButtonText
    this.cancelButtonText = cancelButtonText
    this.data = data
  }
}