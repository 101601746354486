import _ from 'lodash'
import { PrettyJCALComponentNode, PrettyJCALPropertyNode } from '@/services/Pretty'
import { Reservation } from '@/models/Reservation'
import { v4 as guid } from 'uuid'

const re = /^([\\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\\:?00)([\\.,]\d+(?!:))?)?(\17[0-5]\d([\\.,]\d+)?)?([zZ]|([\\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

export class Game {
  private properties: PrettyJCALPropertyNode[];

  public get uid(): string {
    return this.get('uid')?.value
  }

  public get start(): Date {
    return this.getDate() || new Date(2000, 1, 1)
  }

  public get dtstart(): string {
    const dtstart = this.get('dtstart')
    return dtstart?.value.substring(0, 16)
  }
  public set dtstart(val: string) {
    const s = `${val}:00`
    const dtstart = this.get('dtstart')
    if (dtstart) {
      dtstart.value = s
    } else {
      this.properties.push(this.createParam('dtstart', s, 'date-time', { tzid: 'W. Europe Standard Time' }))
    }
  }

  public get dtend(): string {
    const dtend = this.get('dtend')
    return dtend?.value.substring(0, 16)
  }
  public set dtend(val: string) {
    const s = `${val}:00`
    const dtend = this.get('dtend')
    if (dtend) {
      dtend.value = s
    } else {
      this.properties.push(this.createParam('dtend', s, 'date-time', { tzid: 'W. Europe Standard Time' }))
    }
  }

  public get weekday(): string {
    return this.getWeekday(this.start)
  }

  public get monthname(): string {
    return this.getMonth(this.start)
  }
  
  public get summary(): string {
    return this.get('summary')?.value;
  }
  public set summary(val: string) {
    const summary = this.get('summary')
    if (summary) {
      summary.value = val
    } else {
      this.properties.push(this.createParam('summary', val))
    }
  }

  public get description(): string {
    return this.get('description')?.value;
  }
  public set description(val: string) {
    const description = this.get('description')
    if (description) {
      description.value = val
    } else {
      this.properties.push(this.createParam('description', val))
    }
  }

  public get location(): string {
    return this.get('location')?.value;
  }
  public set location(val: string) {
    const location = this.get('location')
    if(location) {
      location.value = val
    } else {
      this.properties.push(this.createParam('location', val))
    }
  }

  public fadeOut: () => void = () => _;
  public register = (fadeOut: () => void) => { this.fadeOut = fadeOut };

  public get reservations(): Reservation[] {
    const res: Reservation[] = [];
    for (const p of this.properties) {
      if (p.name?.startsWith(Reservation.PREFIX)) {
        res.push(new Reservation(p));
      }
    }
    return res;
  }
  public set reservations(reservations: Reservation[]) {
    const rem = this.properties.filter(p => p.name?.startsWith(Reservation.PREFIX));
    for (const p of rem) {
      _.remove(this.properties, p);
    }
    for (const r of reservations) {
      this.properties.push(r.p);
    }
  }  

  constructor(properties: PrettyJCALPropertyNode[] = []) {
    this.properties = properties;
    if (this.properties.length === 0) {
      this.properties.push(this.createParam('uid', guid()))
      this.properties.push(this.createParam('dtstamp', new Date().toISOString(), 'date-time'))
      this.description = ''
      this.location = ''
      this.summary = ''
      const d = new Date() // '2022-08-29T22:47:56.678Z:00'
      this.dtstart = d.toISOString().substring(0, 16)
      this.dtend = d.toISOString().substring(0, 16)
    }
    this.getReservations();
  }

  public copy(): Game {
    return new Game(this.properties)
  }

  public getDateTime(): string {
    return `${this.weekday} ${this.start.getDate()}. ${this.monthname} ${this.start.getFullYear()}, ${this.start.getHours()}:${this.start.getMinutes()} Uhr`;
  }

  public toComponentNode(): PrettyJCALComponentNode {
    return { name: 'vevent', components: [], properties: this.properties }
  }

  public addReservation(res: Reservation) {
    this.properties.push(res.p)
    this.updateDescription()
  }

  public deleteReservation(res: Reservation) {
    _.remove(this.properties, pn => pn.name === res.name)
    this.updateDescription()
  }

  private createParam(name: string, value: string, type = 'text', parameters: object | undefined = undefined): PrettyJCALPropertyNode {
    return {
      name,
      value,
      type,
      parameters
    }
  }

  private updateDescription() {
    const tix = '\n__ Tickets __'
    const tixIdx = this.description.indexOf(tix)
    if (tixIdx > -1) {
      this.description = this.description.substring(0, tixIdx)
    }
    this.description += tix
    for (const res of _.sortBy(this.reservations, (a: Reservation) => a.seatid)) {
      this.description += `\n${res.displayName()} (${res.phone}): ${res.display()}`
    }
  }

  private getReservations() {
    for (const p of this.properties) {
      if (p.name?.startsWith(Reservation.PREFIX)) {
        this.reservations.push(new Reservation(p.value));
      }
    }
  }

  private get(name: string): PrettyJCALPropertyNode | null {
    const prop = _.find(this.properties, p => p.name === name);
    return prop ? prop : null;
  }

  private getDate(): Date | null {
    const dtstart = this.get('dtstart');
    if (dtstart && re.test(dtstart.value)) {
      return new Date(dtstart.value);
    }
    return null;
  }

  private getWeekday(d: Date): string {
    const w = d.getDay();
    if (!isNaN(w)) {
      switch (w) {
        case 0:
          return 'So';
        case 1:
          return 'Mo';
        case 2:
          return 'Di';
        case 3:
          return 'Mi';
        case 4:
          return 'Do';
        case 5:
          return 'Fr';
        case 6:
          return 'Sa';
        default:
          return '';
      }
    }
    else {
      return '';
    }
  }

  private getMonth(d: Date): string {
    const m = d.getMonth();
    if (!isNaN(m)) {
      switch (m) {
        case 0:
          return 'Jan.';
        case 1:
          return 'Feb.';
        case 2:
          return 'März';
        case 3:
          return 'Apr.';
        case 4:
          return 'Mai';
        case 5:
          return 'Jun.';
        case 6:
          return 'Jul.';
        case 7:
          return 'Aug.';
        case 8:
          return 'Sept.';
        case 9:
          return 'Okt.';
        case 10:
          return 'Nov.';
        case 11:
          return 'Dez.';
        default:
          return '';
      }
    }
    else {
      return '';
    }
  }
}
