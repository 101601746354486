import { ApiResponse } from "./ApiResponse";

export class ServiceResponse<T, K = T | null> {

  public success: boolean
  public response: Response
  public data: T

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(apiResponse: ApiResponse<unknown>, data: T) {
    this.success = apiResponse.success
    this.response = apiResponse.response
    this.data = data
  }
}