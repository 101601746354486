import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tickets_game_show = _resolveComponent("tickets-game-show")!
  const _component_tickets_game_edit = _resolveComponent("tickets-game-edit")!

  return ($props.viewProperties && $props.viewProperties.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "event",
        id: $setup.elementId
      }, [
        _createElementVNode("article", {
          class: _normalizeClass(["game", $setup.fadeOut ? 'fadeout' : ''])
        }, [
          (!$setup.editMode)
            ? (_openBlock(), _createBlock(_component_tickets_game_show, {
                key: 0,
                "view-properties": $props.viewProperties,
                onEdit: $setup.edit,
                onDelete: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('delete', $props.viewProperties.game))),
                onUpdateReg: $setup.updateReg,
                onAddReservation: $setup.addReservation,
                onDeleteRes: $setup.deleteRes
              }, null, 8, ["view-properties"]))
            : _createCommentVNode("", true),
          ($setup.editMode)
            ? (_openBlock(), _createBlock(_component_tickets_game_edit, {
                key: 1,
                "view-properties": $props.viewProperties,
                onSave: $setup.save,
                onCancel: $setup.cancel
              }, null, 8, ["view-properties"]))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}