import { MessageProps, helpers } from '@vuelidate/validators'

// messages
export const minLengthMsg = (p: MessageProps) => `Mind. ${p.$params.min} Zeichen erforderlich`
export const maxLengthMsg = (p: MessageProps) => `Max. ${p.$params.max} Zeichen erlaubt`
export const requiredMsg = `Das Feld darf nicht leer sein`
export const invalidPhoneMsg = `Ungültige Mobile-Nummer (z.B. 079 123 45 67)`

// validators
export const phoneClean = (value: string) => value?.replace(/[^\d+]/g, '')
export const phoneRegex = /^(\+?41|0)?([\d]{9,9})$/
export const phoneValidator = (value: string | undefined | null) => { 
  // ignore optional
  if(!helpers.req(value)) {
    return true
  }
  // return false if empty, null or undefined
  if (!value) {
    return false
  }
  // clean input
  const clean = phoneClean(value)
  return phoneRegex.test(clean)
}

// formatters
export const formatPhone = (val: string): string | null => {
  const clean = phoneClean(val)
  const ma = phoneRegex.exec(clean)
  if (ma && ma[2]) {
    const formatted = `${ma[2].substring(0,2)} ${ma[2].substring(2,5)} ${ma[2].substring(5,7)} ${ma[2].substring(7,9)}`
    return `+41 ${formatted}`
  }
  return null
}