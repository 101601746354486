import { PrettyJCALPropertyNode } from '@/services/Pretty';

export class Reservation {

  public static PREFIX = 'x-reservation-'
  
  public p: PrettyJCALPropertyNode;

  public get firstname() : string {
    return this.p.parameters.firstname
  }
  public set firstname(v : string) {
    this.p.parameters.firstname = v
  }
  
  public get lastname() : string {
    return this.p.parameters.lastname
  }
  public set lastname(v : string) {
    this.p.parameters.lastname = v
  }

  public get registrationid() : string {
    return this.p.parameters.registrationid
  }
  public set registrationid(v : string) {
    this.p.parameters.registrationid = v
  }

  public get email() : string {
    return this.p.parameters.email
  }
  public set email(v : string) {
    this.p.parameters.email = v
  }

  public get seatid() : string {
    return this.p.parameters.seatid
  }
  public set seatid(v : string) {
    this.name = v
    this.p.parameters.seatid = v
  }

  public get seatblock() : string {
    return this.p.parameters.seatblock
  }
  public set seatblock(v : string) {
    this.p.parameters.seatblock = v
  }

  public get seatrow() : string {
    return this.p.parameters.seatrow
  }
  public set seatrow(v : string) {
    this.p.parameters.seatrow = v
  }

  public get seatno() : string {
    return this.p.parameters.seatno
  }
  public set seatno(v : string) {
    this.p.parameters.seatno = v
  }

  public get seatowner() : string {
    return this.p.parameters.seatowner
  }
  public set seatowner(v : string) {
    this.p.parameters.seatowner = v
  }

  public get phone() : string {
    return this.p.value
  }
  public set phone(v : string) {
    this.p.value = v
  }

  public get name() : string {
    return this.p.name ?? ''
  }
  public set name(v : string) {
    this.p.name = `${Reservation.PREFIX}${v}`
  }

  constructor(p?: PrettyJCALPropertyNode) {
    if (p) {
      this.p = p
    }
    else {
      this.p = { name: '', type: 'text', value: '', parameters: {} }
    }
  }

  public displayName(): string {
    return `${this.firstname} ${this.lastname}`
  }

  public display(): string {
    return `${this.seatblock}, Reihe: ${this.seatrow}, Nummer: ${this.seatno} (${this.seatowner})`
  }
}
